<template>
    <div class="loginPage">
        <div class="logo" :style="{height:`${winH * 0.2}px`}">
            <img :src="logoImg ? logoImg : require('../../public/transform/defaultLogo.png')" class="login"/>
        </div>
        <div class="form_box">
            <div class="tab">
                <span class="tab_title" v-if="loginModeShow.school" :class="{on:loginParams.loginMode=='school'}" @click="loginParams.loginMode = 'school'">
                    学校登录
<!--                    <h3>学校登录</h3>-->
                </span>
                <span class="tab_title" v-if="loginModeShow.default"  :class="{on:loginParams.loginMode=='default'}" @click="loginParams.loginMode = 'default'">
                    账号登录
<!--                    <h3>账号登录</h3>-->
                </span>
            </div>
            <van-form @submit="onLogin">
                <van-field
                    v-model="schoolData.valueText"
                    name="学校"
                    label="学校"
                    placeholder="点击选择学校"
                    readonly
                    :rules="[{ required: true, message: '请选择学校' }]"
                    @click="schoolData.showPicker=true"
                    v-if="loginParams.loginMode=='school'"
                />
                <van-action-sheet v-model="schoolData.showPicker" :round="false">
                    <van-picker
                        show-toolbar
                        :columns="schoolData.columns"
                        @confirm="schoolChange"
                        @cancel="schoolData.showPicker=false"
                    />
                </van-action-sheet>

                <van-field
                    v-model="campusData.valueText"
                    name="校区"
                    label="校区"
                    placeholder="点击选择校区"
                    readonly
                    :rules="[{ required: true, message: '请选择校区' }]"
                    @click="campusData.showPicker=true"
                    v-if="loginParams.loginMode=='school' && loginParams.schoolId"
                />
                <van-action-sheet v-model="campusData.showPicker" :round="false">
                    <van-picker
                        show-toolbar
                        :columns="campusData.columns"
                        @confirm="campusChange"
                        @cancel="campusData.showPicker=false"
                    />
                </van-action-sheet>


                <van-field
                    v-model="gradeLevelData.valueText"
                    name="年级级别"
                    label="年级级别"
                    placeholder="点击选择年级级别"
                    readonly
                    :rules="[{ required: true, message: '请选择年级级别' }]"
                    @click="gradeLevelData.showPicker=true"
                    v-if="loginParams.loginMode=='school' && loginParams.campusId"
                />
                <van-action-sheet v-model="gradeLevelData.showPicker" :round="false">
                    <van-picker
                        show-toolbar
                        :columns="gradeLevelData.columns"
                        @confirm="gradeLevelChange"
                        @cancel="gradeLevelData.showPicker=false"
                    />
                </van-action-sheet>

                <van-field
                    v-model="classData.valueText"
                    name="班级"
                    label="班级"
                    placeholder="点击选择班级"
                    readonly
                    :rules="[{ required: true, message: '请选择班级' }]"
                    @click="classData.showPicker=true"
                    v-if="loginParams.loginMode=='school' && loginParams.level"
                />
                <van-action-sheet v-model="classData.showPicker" :round="false">
                    <van-picker
                        show-toolbar
                        :columns="classData.columns"
                        @confirm="classChange"
                        @cancel="classData.showPicker=false"
                    />
                </van-action-sheet>

                <van-field
                    v-model="loginParams.username"
                    name="姓名"
                    label="姓名"
                    placeholder="姓名"
                    :rules="[{ required: true, message: '请填写姓名' }]"
                    v-if="loginParams.loginMode=='school'"
                />

                <van-field
                    v-model="loginParams.username"
                    name="用户名"
                    type="tel"
                    label="用户名"
                    placeholder="请输入手机号"
                    :rules="[{ required: true, message: '请填写用户名' },{ validator, message: '请输入正确的用户名' }]"
                    v-if="loginParams.loginMode !='school'"
                />

                <van-field
                        v-model="loginParams.password"
                        :type="showPassword?'text':'password'"
                        name="密码"
                        label="密码"
                        placeholder="输入6-20位字符密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                        @click-right-icon="showPassword = !showPassword"
                >
                    <div class="inputType" slot="right-icon">
                        <img v-if="!showPassword" src="../../public/iconImg/open-eye.png" alt="">
                        <img v-else src="../../public/iconImg/close-eye.png" alt="">
                    </div>
                </van-field>
                <div class="submit_btn" :class="{disabled:!submitAble}" @click="onLogin">登录</div>
                <div class="agreement">
                    注册和登录即代表您同意 <span class="agreement_text" @click="userAction('agreement')">《服务协议及隐私政策》</span>
                </div>
            </van-form>
        </div>
        <div class="footer">
            <!--<div class="action_item retrieve" @click="userAction('retrieve')">忘记密码</div>-->
            <!--<div class="action_item register" v-if="false" @click="userAction('register')">注册账号</div>-->
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import UserApi from "../api/UserApi";
    import {loginApi} from '@/api/index'

    export default {
        name: "Login",
        data() {
            return {
                //窗口高度
                winH:0,
                loginParams:{
                    loginMode: 'school',
                    username: undefined,
                    schoolId: undefined,
                    campusId: undefined,
                    level:undefined,
                    classId: undefined,
                    phone: undefined,
                    password: undefined,
                },
                //登录模式
                loginModeShow  : {
                    'school' : true,
                    'default' : true
                },
                submitAble: true,
                phone: '',
                password: '',
                showPassword:false,
                schoolData: {
                    showPicker: false,
                    valueText : '',
                    columns : [],
                },
                campusData: {
                    showPicker: false,
                    valueText : '',
                    columns : [],
                },
                gradeLevelData: {
                    showPicker: false,
                    valueText : '',
                    columns : [],
                },
                classData: {
                    showPicker: false,
                    valueText : '',
                    columns : [],
                }
            }
        },
        mounted() {
            this.pageInit();
        },
        computed: {
            ...mapGetters({
                //logo信息
                logoInfo: 'common/getLogoInfo',
                //获取学校id
                schoolId: 'common/getSchoolId'
            }),
            logoImg(){
                return this.getLogoImg()
            }
        },
        methods: {
            ...mapMutations({
                //设置用户信息
                setUserInfo:'user/setUserInfo'
            }),
            //用户操作跳转
            userAction(type){
                let path;
                switch (type) {
                    case 'retrieve':
                        path = '/Retrieve';
                        break;
                    case 'register':
                        path = '/Register';
                        break;
                    case 'agreement':
                        path = '/Agreement'
                        break;
                }
                if(path) this.$router.push({path:path});
            },
            //用户名校验
            validator(val) {
                return this.$A.isMobile(val);
            },

            //登录
            onLogin(){
                if(this.submitAble){
                    this.submitAble = false;
                    if (this.loginParams.loginMode === 'school'){              
                        if (!this.loginParams.schoolId){

                        }
                    }
                    UserApi.login(this.loginParams).then(res=>{
                        console.log(res);
                        if(res.success){
                            this.$toast.success('登录成功');
                            //用户信息写入本地缓存
                            this.$L.myLocal('__USER_INFO__',res.data);
                            let session = res.data.session || '';
                            this.$L.myLocal('__SESSION__',session);

                            //设置vuex状态用户信息
                            this.setUserInfo(res.data);


                            setTimeout(()=>{
                                this.$router.replace({path:'/Task'});
                            },1000)
                        }
                        this.submitAble = true;
                    })
                }

            },
            //页面初始化
            pageInit(){
                this.winH = document.documentElement.clientHeight || document.body.clientHeight;
                this.getSchoolList()
                console.log('this.winH', this.winH);
            },


            // 获取学校列表
            getSchoolList() {
                if (this.type === 'default'){
                    this.loading = false
                    return
                }
                let params = {
                    getOpenUserLogin: 1,
                    schoolId: this.loginParams.schoolId,
                }
                loginApi.getSchools(params).then(res => {
                    if (res.success) {
                        if (params.schoolId) {
                            // this.school = res.data[0].id
                            // this.schoolText = res.data[0].name
                            // this.schoolChange({id: res.data[0].id, text: res.data[0].name})
                        }
                        this.schoolData.columns = []

                        res.data.forEach( item => {
                            this.schoolData.columns.push({
                                text : item.name,
                                id : item.id
                            })
                        })
                        this.loading = false
                    }
                })
            },
            // 获取校区列表
            getCampusList() {
                let params = {
                    schoolid: this.loginParams.schoolId,
                    getOpenUserLogin: 1
                }
                loginApi.getCampus(params).then(res => {
                    if (res.success) {
                        this.campusData.columns = []
                        res.data.forEach( item => {
                            this.campusData.columns.push({
                                text : item.name,
                                id : item.id
                            })
                        })
                    }
                })
            },
            // 获取年级级别列表
            getGradeLevelList() {
                let params = {
                    getOpenUserLogin: 1,
                    schoolid: this.loginParams.schoolId,
                    campusid: this.loginParams.campusId
                }
                loginApi.getGradeLevel(params).then(res => {
                    if (res.success) {
                        this.gradeLevelData.columns = []
                        res.data.forEach( item => {
                            this.gradeLevelData.columns.push({
                                text : item.name,
                                id : item.id
                            })
                        })
                    }
                })
            },
            // 获取班级列表
            getClassList() {
                let params = {
                    schoolid: this.loginParams.schoolId,
                    campusid: this.loginParams.campusId,
                    level: this.loginParams.level,
                    getOpenUserLogin: 1
                }
                loginApi.getClasses(params).then(res => {
                    if (res.success) {
                        this.classData.columns = []
                        res.data.forEach(item => {
                            this.classData.columns.push({
                                text : item.name,
                                id : item.id
                            })
                        })
                    }
                })
            },
            schoolChange(value) {
                if (value){
                    this.loginParams.schoolId = value.id
                    this.schoolData.valueText = value.text
                    this.schoolData.showPicker = false
                    this.getCampusList()
                    //清空校区，级别，班级选择
                    this.campusData.valueText = undefined
                    this.gradeLevelData.valueText = undefined
                    this.classData.valueText = undefined
                    this.loginParams.campusId = undefined
                    this.loginParams.level = undefined
                    this.loginParams.classId = undefined
                }
            },
            campusChange(value) {
                if (value) {
                    this.loginParams.campusId = value.id
                    this.campusData.valueText = value.text
                    this.getGradeLevelList()
                    this.campusData.showPicker = false

                    //清空级别，班级选择
                    this.gradeLevelData.valueText = undefined
                    this.classData.valueText = undefined
                    this.loginParams.level = undefined
                    this.loginParams.classId = undefined
                }
            },
            gradeLevelChange(value) {
                if (value) {
                    this.loginParams.level = value.id
                    this.gradeLevelData.valueText = value.text
                    this.gradeLevelData.showPicker = false
                    this.getClassList()

                    //清空班级选择
                    this.classData.valueText = undefined
                    this.loginParams.classId = undefined
                }
            },
            classChange(value) {
                if (value) {
                    this.loginParams.classId = value.id
                    this.classData.valueText = value.text
                    this.classData.showPicker = false
                }
            },

            getLogoImg() {
                console.log('getLogoImg ')
                let schoolId = this.$A.getHrefQueryByName('schoolId');
                if (!schoolId){
                    schoolId = this.$L.myLocal('__SCHOOL_ID__') || this.schoolId;
                }

                let target = this.logoInfo.filter(item => item.schoolId == schoolId);
                // console.log('target', target);
                // debugger
                if (target[0]) {
                    this.loginModeShow = target[0].loginModeShow
                    this.loginParams.loginMode = target[0].loginMode
                    return target[0].logoSrc;
                }else {
                    this.$L.myLocal('__SCHOOL_ID__', this.logoInfo[0].schoolId);
                    this.loginModeShow = this.logoInfo[0].loginModeShow
                    this.loginParams.loginMode = this.logoInfo[0].loginMode
                    return this.logoInfo[0].logoSrc
                }

            },
        }
    }
</script>

<style lang="scss">
    .loginPage {
        @extend .flex_column;
        overflow: hidden;

        & > div {
            width: 100%;
        }

        .logo {
            /*height: 30%;*/
            @extend .flex_row_center;

            img {
                max-width: 90%;
                object-fit: cover;
                object-position: center;
                /* 最大高度设置 */
                max-height: 90%;
            }
        }

        .form_box {
            @extend .flex_height_auto;
            @extend .flex_column;
            padding: 0 5%;
            width: 90%;
            .van-field__control{
                font-size: 24px;
                /*font-weight: bold;*/
                color: $defaultColor !important;
            }
            .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
                color: $warnColor;
            }
            .van-field__control::placeholder{
                font-size: 16px;
            }
            .van-field__label{
                @extend .flex_row_center;
                span{
                    /*font-size: 24px;*/
                }


            }
            //判断ipad
            @media only screen
            and (min-device-width : 768px)
            and (max-device-width : 1024px){
                font-size: 42px;
            }
            .inputType{
                @extend .flex_row_center;
                img{
                    height: 20px;
                    width: 20px;
                }
            }

            .submit_btn{
                @extend .flex_row_center;
                width: 100%;
                height: 45px;
                border-radius: 50px;
                background: $themeColor;
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                margin-top: 60px;
                transition: all .2s ease;
                &:active{
                    background: $primaryColor;
                }
                &.disabled{
                    background: #EEEEEE;
                    pointer-events:none;
                }
            }
            .agreement{
                margin-top: 12px;
                font-size: 14px;
                @media only screen
                and (min-device-width : 768px)
                and (max-device-width : 1024px){
                    font-size: 26px;
                }
                .agreement_text{
                    color: #1879E5;
                }

            }

            .tab {
                // text-align: center;
                margin-left: 18px;

                .tab_title {
                    line-height: 45px;
                    text-align: center;
                    border-bottom: 2px solid transparent;
                    width: 80px;
                    height: 45px;
                    display: inline-block;
                    padding-bottom: 6px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                }

                .tab_title.on {
                    border-bottom-color: #1989fa;
                }
            }

        }

        .footer {
            margin-top: 24px;
            padding-bottom: 60px;
            @extend .flex_row_center;

            .action_item {
                flex: 1;
                margin-right: 70px;
                &:last-child {
                    margin-right: 0;
                }

                &.retrieve {
                    /*text-align: right;*/
                    color: $warnColor;
                }

                &.register {
                    /*text-align: left;*/
                    color: $defaultColor;
                }
            }
        }

        //兼容ipad
        @media only screen
        and (min-device-width : 768px)
        and (max-device-width : 1024px){
            .form_box {
                @extend .flex_height_auto;
                @extend .flex_column;
                padding: 0 5%;
                width: 90%;
                font-size: 42px;
                .van-field__control{
                    font-size: 48px;
                    /*font-weight: bold;*/
                }
                .van-field__label{
                    span{
                        font-size: 24px;
                    }


                }
                .van-field__control::placeholder{
                    font-size: 32px;
                }
                .inputType{
                    img{
                        height: 40px;
                        width: 40px;
                    }
                }
                .van-field__error-message{
                    font-size: 28px;
                }

                .submit_btn{
                    @extend .flex_row_center;
                    height: 90px;
                    border-radius: 100px;
                    font-size: 36px;
                    margin-top: 120px;
                }
                .agreement{
                    margin-top: 24px;
                    font-size: 26px;

                }

                .tab {
                    // text-align: center;
                    margin-left: 18px;

                    .tab_title {
                        font-size: 24px;
                        /*font-weight: bold;*/
                        text-align: center;
                        border-bottom: 2px solid transparent;
                        width: 160px;
                        height: 45px;
                        display: inline-block;
                        padding-bottom: 6px;
                        margin-bottom: 10px;
                        margin-right: 10px;
                    }

                    .tab_title.on {
                        border-bottom-color: #1989fa;
                    }
                }

            }

            .footer {
                margin-top: 48px;
                padding-bottom: 30px;

                .action_item {
                    margin-right: 140px;
                }
            }
        }
    }
</style>